import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    version: '0.05',
    uuid: '',
    salvoconducto: 'HeladosCapraWeb1',
    menuadmin: true,
    usuario: {}

  },
  mutations: {
    modifUuid(state, payload) {
      state.uuid = payload
    },
    modifMenuadmin(state, payload) {
      state.menuadmin = payload
    }
  },
  getters: {
    dameUuid(state) {
      return state.uuid
    },
    dameMenuadmin(state) {
      return state.menuadmin
    },
    verVersion(state) {
      return state.version
    }
  }
})
