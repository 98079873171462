<template>
    <span>Helados <b>Capra</b> App de Pedidos</span>
</template>
<script>
  export default {
    name: 'notification-sample'
  }
</script>
<style>
</style>
